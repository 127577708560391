import { createContext, useCallback, useContext, useState } from 'react';

import { Breakpoint } from '@mui/material';

import ConfirmationDialog from '~/components/confirmation-dialog';

type ContentFunction = () => React.ReactNode;
interface ConfirmationConfig {
  title?: string;
  buttonYesLabel?: string;
  buttonNoLabel?: string;
  content?: React.ReactNode | ContentFunction;
  onYes: () => Promise<boolean | void>; // Return false will not close dialog
  onClose: () => void;
  noAction?: boolean;
  maxWidth?: Breakpoint;
}
const ConfirmationDialogContext = createContext<{
  setConfig: (config: ConfirmationConfig & { open: boolean }) => void;
}>({} as any);

const isContentFunction = (value: React.ReactNode | ContentFunction): value is ContentFunction => {
  return typeof value === 'function';
};

export const useConfirmationDialog = () => {
  const { setConfig } = useContext(ConfirmationDialogContext);
  const confirmDialog = useCallback(
    async (options?: Partial<ConfirmationConfig>) => {
      if (!setConfig) {
        throw new Error('Missing wrap app with WithConfirmationDialog provider');
      }
      const promise = new Promise<boolean>((resolve, reject) => {
        setConfig({
          ...options,
          open: true,
          onClose: () => {
            options?.onClose?.();
            resolve(false);
          },
          onYes: async () => {
            const isOk = await options?.onYes?.();
            if (isOk !== false) {
              resolve(true);
            }
            return isOk;
          },
          title: options?.title || '',
        });
      });
      return promise;
    },
    [setConfig]
  );

  return { confirmDialog };
};

export const WithConfirmationDialog: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const [config, setConfig] = useState<ConfirmationConfig & { open: boolean }>();
  const resolvedContent = isContentFunction(config?.content) ? config?.content() : config?.content;

  return (
    <ConfirmationDialogContext.Provider value={{ setConfig }}>
      {props.children}
      <ConfirmationDialog
        title={config?.title}
        open={!!config?.open}
        buttonYesLabel={config?.buttonYesLabel}
        buttonNoLabel={config?.buttonNoLabel}
        onClose={() => {
          config?.onClose();
          if (config) {
            setConfig({ ...config, open: false });
          }
        }}
        onYes={async () => {
          const isOk = await config?.onYes();
          if (config && isOk !== false) {
            setConfig({ ...config, open: false });
          }
        }}
        noAction={!!config?.noAction}
        maxWidth={config?.maxWidth}
      >
        {resolvedContent}
      </ConfirmationDialog>
    </ConfirmationDialogContext.Provider>
  );
};
