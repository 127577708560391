import { useCallback, useMemo, useState } from 'react';

import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Button } from '@mui/material';
import Link from '@mui/material/Link';
import { DataGridPro, GridActionsCellItem, GridColDef } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import CreateApiKeyDialog from './create-api-key-dialog';
import PrivateKeyInfoDialog from './private-info-dialog';

import UserLayout from '~/components/app-layout/user-layout';
import { ROWS_PER_PAGE_OPTIONS } from '~/constants/view';
import { AppRouteEnum } from '~/enums/route.enum';
import { useDeleteApiKeyMutation, useGetMerchantAccountQuery } from '~/graphql/merchant/types';
import { useConfirmationDialog } from '~/hooks/use-confirmation-dialog';

const useStyles = makeStyles()((theme) => ({
  btnCreate: {
    marginLeft: 'auto',
  },
  secretKeyWrapper: {
    justifyContent: 'space-between !important',
  },
}));

const APIKeys = () => {
  const { classes } = useStyles(undefined, { props: {} });
  const { t } = useTranslation();
  const { confirmDialog } = useConfirmationDialog();
  const [openAddApiKeyDialog, setOpenAddApiKeyDialog] = useState<boolean>(false);
  const [currentPrivateKeyShowed, setCurrentPrivateKeyShowed] = useState<
    { uuid: string; secretKey: string } | undefined
  >();

  const [deleteApiKey] = useDeleteApiKeyMutation();

  const { data, loading: loadingGetMerchantAccount } = useGetMerchantAccountQuery({
    fetchPolicy: 'cache-and-network',
  });

  const onOpenDeleteApiKeyDialog = useCallback(
    async (row: Row) => {
      confirmDialog({
        async onYes() {
          await deleteApiKey({
            variables: {
              input: {
                uuid: row.id,
              },
            },
          });
        },
      });
    },
    [confirmDialog, deleteApiKey]
  );

  type Row = typeof rows[number];
  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'name',
        headerName: t('name'),
        sortable: false,
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'secretKey',
        headerName: t('api_keys_page.secret_key'),
        minWidth: 140,
        sortable: false,
        flex: 1,
        cellClassName: classes.secretKeyWrapper,
        renderCell({ row: { uuid, secretKey } }) {
          return (
            <>
              <p>**************</p>
              <Button onClick={() => onOpenSecretKeyDetail({ uuid, secretKey })}>Show</Button>
            </>
          );
        },
      },
      {
        field: 'createdAt',
        headerName: t('created_at'),
        minWidth: 200,
        sortable: false,
        flex: 1,
        valueFormatter: ({ value }) => (value ? moment(value) : '-'),
      },
      {
        field: 'actions',
        type: 'actions',
        width: 100,
        align: 'right',
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => onOpenDeleteApiKeyDialog(params.row)}
              color="inherit"
            />,
          ];
        },
      },
    ],
    [classes.secretKeyWrapper, onOpenDeleteApiKeyDialog, t]
  );

  const onOpenSecretKeyDetail = (value: { uuid: string; secretKey: string }) => {
    setCurrentPrivateKeyShowed(value);
  };

  const handleCreateBtnClicked = () => {
    setOpenAddApiKeyDialog(true);
  };

  const onCloseCreateApiKeyDialog = () => {
    setOpenAddApiKeyDialog(false);
  };

  const rows = useMemo(
    () =>
      (data?.getMerchantAccount?.apiKeys || []).map((item) => {
        return {
          id: item.uuid,
          ...item,
        };
      }),
    [data]
  );

  return (
    <UserLayout>
      {openAddApiKeyDialog ? <CreateApiKeyDialog open={true} onClose={onCloseCreateApiKeyDialog} /> : null}
      {currentPrivateKeyShowed ? (
        <PrivateKeyInfoDialog
          open={true}
          value={currentPrivateKeyShowed}
          onClose={() => {
            setCurrentPrivateKeyShowed(undefined);
          }}
        />
      ) : null}
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <Link
          component={RouterLink}
          to={AppRouteEnum.API_KEYS}
          color="text.primary"
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          {t('api_keys_page.title')}
        </Link>
      </Breadcrumbs>
      <Button className={classes.btnCreate} variant="outlined" onClick={handleCreateBtnClicked}>
        Create
      </Button>
      <div style={{ width: '100%', height: 500 }}>
        <DataGridPro
          pagination
          rows={rows}
          loading={loadingGetMerchantAccount}
          columns={columns}
          rowCount={0}
          pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
          disableVirtualization
          disableRowSelectionOnClick
          disableColumnMenu
        />
      </div>
    </UserLayout>
  );
};

export default APIKeys;
