import { useCallback, useMemo, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Button, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { DataGridPro, GridColDef, GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import CreateWebhookDialog from './create-webhook-dialog';

import UserLayout from '~/components/app-layout/user-layout';
import CodeBlock from '~/components/code-block';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE, ROWS_PER_PAGE_OPTIONS } from '~/constants/view';
import { AppRouteEnum } from '~/enums/route.enum';
import { useGetMerchantAccountQuery, useRemoveWebhookMutation } from '~/graphql/merchant/types';
import { useConfirmationDialog } from '~/hooks/use-confirmation-dialog';

const useStyles = makeStyles()((theme) => ({
  btnCreate: {
    marginLeft: 'auto',
  },
}));

const Webhooks = () => {
  const { classes } = useStyles(undefined, { props: {} });
  const { t } = useTranslation();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    page: DEFAULT_PAGE,
  });
  const { confirmDialog } = useConfirmationDialog();
  const { enqueueSnackbar } = useSnackbar();
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'createdAt', sort: 'desc' }]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>();
  const [openAddApiKeyDialog, setOpenAddApiKeyDialog] = useState<boolean>(false);
  const [removeWebhook] = useRemoveWebhookMutation();
  const { data: getMerchantAccountData, loading: loadingGetMerchantAccount } = useGetMerchantAccountQuery({
    fetchPolicy: 'cache-first',
  });

  const showSecretKey = useCallback(
    (content: string) => {
      confirmDialog({
        title: t('webhooks.secret_key'),
        content: <CodeBlock codeString={content} />,
        noAction: true,
        maxWidth: 'md',
      });
    },
    [t, confirmDialog]
  );

  const confirmDelete = useCallback(
    (itemId: string, name: string) => {
      confirmDialog({
        title: t('webhooks.confirm_delete_title'),
        content: t('webhooks.confirm_delete_content', { name }),
        buttonYesLabel: t('webhooks.confirm_delete_yes_label'),
        buttonNoLabel: t('webhooks.confirm_delete_no_label'),
        onYes: async () => {
          await removeWebhook({ variables: { uuid: itemId } });
        },
      });
    },
    [t, confirmDialog, removeWebhook]
  );
  // type Row = typeof rows[number];
  const columns = useMemo<GridColDef<any>[]>(
    () => [
      {
        field: 'name',
        headerName: t('name'),
        sortable: false,
        flex: 0.5,
      },
      {
        field: 'url',
        headerName: t('webhooks.url'),
        sortable: false,
        flex: 1,
      },
      {
        field: 'secretKey',
        headerName: t('webhooks.secret_key'),
        flex: 1,
        renderCell({ value }) {
          return (
            <>
              **************{'   '}
              <Button onClick={() => showSecretKey(value)}>{t('show')}</Button>
            </>
          );
        },
        sortable: false,
        filterable: false,
      },
      {
        field: 'createdAt',
        headerName: t('webhooks.create_at'),
        valueFormatter: ({ value }) => (value ? moment(value).format('YYYY-MM-DD HH:mm') : '-'),
        sortable: false,
        flex: 1,
      },
      {
        field: 'delete',
        headerName: '',
        flex: 0.5,
        renderCell({ row }) {
          return <DeleteIcon onClick={() => confirmDelete(row.uuid, row.name)} />;
        },
      },
    ],
    [t, confirmDelete, showSecretKey]
  );

  const handleCreateBtnClicked = () => {
    setOpenAddApiKeyDialog(true);
  };

  const onCloseCreateApiKeyDialog = () => {
    setOpenAddApiKeyDialog(false);
  };

  return (
    <UserLayout>
      {openAddApiKeyDialog ? <CreateWebhookDialog open={true} onClose={onCloseCreateApiKeyDialog} /> : null}
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <Link
          component={RouterLink}
          to={AppRouteEnum.WEBHOOKS}
          color="text.primary"
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          {t('webhooks.title')}
        </Link>
      </Breadcrumbs>
      <Button className={classes.btnCreate} variant="outlined" onClick={handleCreateBtnClicked}>
        {t('create')}
      </Button>
      <div style={{ width: '100%', height: 500 }}>
        <DataGridPro
          pagination
          rows={getMerchantAccountData?.getMerchantAccount?.webhooks.map((row, index) => ({ ...row, id: index })) || []}
          loading={false}
          columns={columns}
          paginationModel={paginationModel}
          paginationMode="server"
          sortingMode="server"
          filterMode="server"
          rowCount={0}
          pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
          sortModel={sortModel}
          filterModel={filterModel}
          disableVirtualization
          disableRowSelectionOnClick
          disableColumnMenu
        />
      </div>
    </UserLayout>
  );
};

export default Webhooks;
