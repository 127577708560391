import { useCallback, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Button, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';

import UserLayout from '~/components/app-layout/user-layout';
import { AppRouteEnum } from '~/enums/route.enum';
import { useUpdateWalletAddressMutation } from '~/graphql/merchant/types';
import { useAccount } from '~/hooks/with-account';

const AddressRegister = () => {
  const { t } = useTranslation();

  const schema = useMemo(
    () =>
      yup.object({
        address: yup.string().required().ethAddress(),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const [updateWalletAddress] = useUpdateWalletAddressMutation();

  const { account } = useAccount();

  interface FormValues extends yup.InferType<typeof schema> {}
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      address: account?.walletAddress || '',
    },
    resolver: yupResolver(schema),
  });

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback(
    async (data: FormValues) => {
      try {
        await updateWalletAddress({
          variables: {
            walletAddress: data.address,
          },
        });
        enqueueSnackbar(t('address_register.register_successfully'), { variant: 'success' });
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
    [enqueueSnackbar, t, updateWalletAddress]
  );

  return (
    <UserLayout>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <Link
          component={RouterLink}
          to={AppRouteEnum.ADDRESS_REGISTER}
          color="text.primary"
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          {t('api_keys_page.title')}
        </Link>
      </Breadcrumbs>
      <div>
        <h1>{t('address_register.title')}</h1>
        <p>{t('address_register.register_address_description')}</p>
        <Controller
          name="address"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              label={t('address_register.title')}
              variant="outlined"
              margin="normal"
              sx={{ marginTop: 0 }}
              error={!!errors.address?.message}
              helperText={errors.address?.message}
              {...field}
            />
          )}
        />
        <Button
          color="primary"
          variant="contained"
          disabled={isSubmitting}
          endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
          onClick={handleSubmit(onSubmit)}
        >
          {t('address_register.register')}
        </Button>
      </div>
    </UserLayout>
  );
};

export default AddressRegister;
