import React, { createContext, useContext, useEffect } from 'react';

import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { useLogout } from './use-logout';

import ScreenLoading from '~/components/screen-loading';
import { MerchantAccount, useGetMerchantAccountQuery } from '~/graphql/merchant/types';
import SignUpPage from '~/pages/sign-up';

const AccountContext = createContext<{ account: MerchantAccount }>({} as any);

export const useAccount = () => useContext(AccountContext);

const useStyles = makeStyles()((theme) => ({
  bottomWrapper: {
    paddingTop: theme.spacing(4),
  },
}));

export const WithAccount: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const { classes } = useStyles(undefined, { props: {} });
  const { t } = useTranslation();
  const { data: getMerchantAccountData, loading: loadingGetMerchantAccount } = useGetMerchantAccountQuery({
    fetchPolicy: 'cache-and-network',
  });

  const logout = useLogout();

  if (loadingGetMerchantAccount) {
    return <ScreenLoading />;
  }

  if (!getMerchantAccountData?.getMerchantAccount) {
    return <SignUpPage />;
  }

  return (
    <AccountContext.Provider value={{ account: getMerchantAccountData.getMerchantAccount }}>
      <Wrap>{props.children}</Wrap>
    </AccountContext.Provider>
  );
};

const Wrap: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const { account } = useAccount();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  // const [updateProfile, { loading: loadingUpdateProfile }] = useUpdateProfileMutation();
  useEffect(() => {
    if (i18n.language !== account.profileLanguage.toLowerCase()) {
      i18n.changeLanguage(account.profileLanguage.toLowerCase());
    }
  }, [account.profileLanguage, i18n]);

  // useEffect(() => {
  //   const onChage = async () => {
  //     if (i18n.language !== account.profileLanguage.toLowerCase()) {
  //       updateProfile({
  //         variables: {
  //           input: {
  //             profileLanguage: i18n.language === 'ja' ? LanguageEnum.Ja : LanguageEnum.En,
  //           },
  //         },
  //       }).catch((error) => {
  //         enqueueSnackbar(error.message, { variant: 'error' });
  //       });
  //     }
  //   };

  //   i18n.on('languageChanged', onChage);
  //   return () => {
  //     i18n.off('languageChanged', onChage);
  //   };
  // }, [account.profileLanguage, enqueueSnackbar, i18n, updateProfile]);

  useEffect(() => {
    moment.tz.setDefault(account.profileTimezone || undefined);
  }, [account.profileTimezone]);

  return <>{props.children}</>;
};
