import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = { context: { apiName: 'merchant' } } as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
};

export type ApiKey = {
  /** Created at */
  createdAt: Scalars['Date'];
  /** Name */
  name: Scalars['String'];
  /** Public key */
  publicKey: Scalars['String'];
  /** Private key */
  secretKey: Scalars['String'];
  /** Uuid */
  uuid: Scalars['String'];
};

export type CreateApiKeyInput = {
  /** Name */
  name: Scalars['String'];
};

export type CreateWebhookInput = {
  /** Name of the webhook */
  name: Scalars['String'];
  /** URL of the webhook endpoint */
  url: Scalars['String'];
};

export type DeleteApiKeyInput = {
  /** Uuid */
  uuid: Scalars['String'];
};

export enum LanguageEnum {
  En = 'EN',
  Ja = 'JA',
}

export type ListPaymentsFilter = {
  /** Merchant uuid, this field is an optional string type */
  merchantUuid?: InputMaybe<Scalars['String']>;
  /** Merchant wallet address, this field is an optional string type */
  merchantWalletAddress?: InputMaybe<Scalars['String']>;
};

export type ListPaymentsResponse = {
  /** Items */
  items: Array<Payment>;
  /** Pagination */
  pagination: Pagination;
};

export type MerchantAccount = {
  /** API keys */
  apiKeys: Array<ApiKey>;
  /** Merchant name */
  name: Scalars['String'];
  /** Profile language */
  profileLanguage: LanguageEnum;
  /** Profile timezone */
  profileTimezone?: Maybe<Scalars['String']>;
  /** Uuid */
  uuid: Scalars['String'];
  /** Wallet address */
  walletAddress?: Maybe<Scalars['String']>;
  /** Webhooks */
  webhooks: Array<Webhook>;
};

export type Mutation = {
  createApiKey: MerchantAccount;
  createWebhook: MerchantAccount;
  deleteApiKey: MerchantAccount;
  registerMerchantAccount: MerchantAccount;
  removeWebhook: MerchantAccount;
  updateWalletAddress: MerchantAccount;
};

export type MutationCreateApiKeyArgs = {
  input: CreateApiKeyInput;
};

export type MutationCreateWebhookArgs = {
  input: CreateWebhookInput;
};

export type MutationDeleteApiKeyArgs = {
  input: DeleteApiKeyInput;
};

export type MutationRegisterMerchantAccountArgs = {
  input: RegisterMerchantAccountInput;
};

export type MutationRemoveWebhookArgs = {
  uuid: Scalars['String'];
};

export type MutationUpdateWalletAddressArgs = {
  walletAddress: Scalars['String'];
};

export type Pagination = {
  /** Current page */
  currentPage: Scalars['Int'];
  /** Items per page */
  itemsPerPage: Scalars['Int'];
  /** Total items */
  totalItems: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

export type Payment = {
  /** Amount */
  amount: Scalars['Float'];
  /** Buyer wallet address */
  buyerWalletAddress?: Maybe<Scalars['String']>;
  /** Created at */
  createdAt: Scalars['Date'];
  /** Description */
  description: Scalars['String'];
  /** Merchant uuid */
  merchantUuid: Scalars['String'];
  /** Merchant wallet address */
  merchantWalletAddress: Scalars['String'];
  /** State */
  state: PaymentStateEnum;
  /** Token */
  token: Token;
  /** Transaction hash */
  transactionHash?: Maybe<Scalars['String']>;
  /** Updated at */
  updatedAt?: Maybe<Scalars['Date']>;
  /** Uuid */
  uuid: Scalars['String'];
};

export enum PaymentStateEnum {
  Confirming = 'CONFIRMING',
  Done = 'DONE',
  Expired = 'EXPIRED',
  Failure = 'FAILURE',
  Waiting = 'WAITING',
}

export type Query = {
  getMerchantAccount: MerchantAccount;
  listPayments: ListPaymentsResponse;
};

export type QueryListPaymentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ListPaymentsFilter>;
};

export type RegisterMerchantAccountInput = {
  /** Name of merchant, this field is a string type with max length of 128. */
  name: Scalars['String'];
  /** Language, this field is an enum type */
  profileLanguage: LanguageEnum;
};

export type Token = {
  /** Created at */
  createdAt: Scalars['Date'];
  /** Description */
  description?: Maybe<Scalars['String']>;
  /** Name */
  name: Scalars['String'];
  /** Symbol */
  symbol: Scalars['String'];
  /** Token address */
  tokenAddress: Scalars['String'];
  /** Updated at */
  updatedAt?: Maybe<Scalars['Date']>;
  /** Uuid */
  uuid: Scalars['String'];
};

export type Webhook = {
  /** Created at */
  createdAt: Scalars['Date'];
  /** Name */
  name: Scalars['String'];
  /** Private key */
  secretKey: Scalars['String'];
  /** Url */
  url: Scalars['String'];
  /** Uuid */
  uuid: Scalars['String'];
};

export type CreateApiKeyMutationVariables = Exact<{
  input: CreateApiKeyInput;
}>;

export type CreateApiKeyMutation = {
  createApiKey: {
    uuid: string;
    name: string;
    profileLanguage: LanguageEnum;
    profileTimezone?: string | null;
    apiKeys: Array<{ uuid: string; name: string; publicKey: string; secretKey: string; createdAt: string }>;
    webhooks: Array<{ uuid: string; name: string; url: string; secretKey: string; createdAt: string }>;
  };
};

export type CreateWebhookMutationVariables = Exact<{
  input: CreateWebhookInput;
}>;

export type CreateWebhookMutation = {
  createWebhook: {
    uuid: string;
    name: string;
    profileLanguage: LanguageEnum;
    profileTimezone?: string | null;
    apiKeys: Array<{ uuid: string; name: string; publicKey: string; secretKey: string; createdAt: string }>;
    webhooks: Array<{ uuid: string; name: string; url: string; secretKey: string; createdAt: string }>;
  };
};

export type DeleteApiKeyMutationVariables = Exact<{
  input: DeleteApiKeyInput;
}>;

export type DeleteApiKeyMutation = {
  deleteApiKey: {
    uuid: string;
    name: string;
    profileLanguage: LanguageEnum;
    profileTimezone?: string | null;
    apiKeys: Array<{ uuid: string; name: string; publicKey: string; secretKey: string; createdAt: string }>;
    webhooks: Array<{ uuid: string; name: string; url: string; secretKey: string; createdAt: string }>;
  };
};

export type RegisterMerchantAccountMutationVariables = Exact<{
  input: RegisterMerchantAccountInput;
}>;

export type RegisterMerchantAccountMutation = {
  registerMerchantAccount: {
    uuid: string;
    name: string;
    profileLanguage: LanguageEnum;
    profileTimezone?: string | null;
  };
};

export type RemoveWebhookMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type RemoveWebhookMutation = {
  removeWebhook: {
    uuid: string;
    name: string;
    profileLanguage: LanguageEnum;
    profileTimezone?: string | null;
    apiKeys: Array<{ uuid: string; name: string; publicKey: string; secretKey: string; createdAt: string }>;
    webhooks: Array<{ uuid: string; name: string; url: string; secretKey: string; createdAt: string }>;
  };
};

export type UpdateWalletAddressMutationVariables = Exact<{
  walletAddress: Scalars['String'];
}>;

export type UpdateWalletAddressMutation = {
  updateWalletAddress: {
    uuid: string;
    name: string;
    profileLanguage: LanguageEnum;
    profileTimezone?: string | null;
    walletAddress?: string | null;
    apiKeys: Array<{ uuid: string; name: string; publicKey: string; secretKey: string; createdAt: string }>;
    webhooks: Array<{ uuid: string; name: string; url: string; secretKey: string; createdAt: string }>;
  };
};

export type GetMerchantAccountQueryVariables = Exact<{ [key: string]: never }>;

export type GetMerchantAccountQuery = {
  getMerchantAccount: {
    uuid: string;
    name: string;
    profileLanguage: LanguageEnum;
    profileTimezone?: string | null;
    walletAddress?: string | null;
    apiKeys: Array<{ uuid: string; name: string; publicKey: string; secretKey: string; createdAt: string }>;
    webhooks: Array<{ uuid: string; name: string; url: string; secretKey: string; createdAt: string }>;
  };
};

export type ListPaymentsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ListPaymentsFilter>;
}>;

export type ListPaymentsQuery = {
  listPayments: {
    items: Array<{
      uuid: string;
      amount: number;
      description: string;
      merchantWalletAddress: string;
      buyerWalletAddress?: string | null;
      transactionHash?: string | null;
      state: PaymentStateEnum;
      createdAt: string;
      updatedAt?: string | null;
      token: { uuid: string; name: string; symbol: string; description?: string | null; tokenAddress: string };
    }>;
    pagination: { totalItems: number; itemsPerPage: number; totalPages: number; currentPage: number };
  };
};

export const CreateApiKeyDocument = gql`
  mutation createApiKey($input: CreateApiKeyInput!) {
    createApiKey(input: $input) {
      uuid
      name
      profileLanguage
      profileTimezone
      apiKeys {
        uuid
        name
        publicKey
        secretKey
        createdAt
      }
      webhooks {
        uuid
        name
        url
        secretKey
        createdAt
      }
    }
  }
`;
export type CreateApiKeyMutationFn = Apollo.MutationFunction<CreateApiKeyMutation, CreateApiKeyMutationVariables>;

/**
 * __useCreateApiKeyMutation__
 *
 * To run a mutation, you first call `useCreateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiKeyMutation, { data, loading, error }] = useCreateApiKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateApiKeyMutation, CreateApiKeyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateApiKeyMutation, CreateApiKeyMutationVariables>(CreateApiKeyDocument, options);
}
export type CreateApiKeyMutationHookResult = ReturnType<typeof useCreateApiKeyMutation>;
export type CreateApiKeyMutationResult = Apollo.MutationResult<CreateApiKeyMutation>;
export type CreateApiKeyMutationOptions = Apollo.BaseMutationOptions<
  CreateApiKeyMutation,
  CreateApiKeyMutationVariables
>;
export const CreateWebhookDocument = gql`
  mutation createWebhook($input: CreateWebhookInput!) {
    createWebhook(input: $input) {
      uuid
      name
      profileLanguage
      profileTimezone
      apiKeys {
        uuid
        name
        publicKey
        secretKey
        createdAt
      }
      webhooks {
        uuid
        name
        url
        secretKey
        createdAt
      }
    }
  }
`;
export type CreateWebhookMutationFn = Apollo.MutationFunction<CreateWebhookMutation, CreateWebhookMutationVariables>;

/**
 * __useCreateWebhookMutation__
 *
 * To run a mutation, you first call `useCreateWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWebhookMutation, { data, loading, error }] = useCreateWebhookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWebhookMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateWebhookMutation, CreateWebhookMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateWebhookMutation, CreateWebhookMutationVariables>(CreateWebhookDocument, options);
}
export type CreateWebhookMutationHookResult = ReturnType<typeof useCreateWebhookMutation>;
export type CreateWebhookMutationResult = Apollo.MutationResult<CreateWebhookMutation>;
export type CreateWebhookMutationOptions = Apollo.BaseMutationOptions<
  CreateWebhookMutation,
  CreateWebhookMutationVariables
>;
export const DeleteApiKeyDocument = gql`
  mutation deleteApiKey($input: DeleteApiKeyInput!) {
    deleteApiKey(input: $input) {
      uuid
      name
      profileLanguage
      profileTimezone
      apiKeys {
        uuid
        name
        publicKey
        secretKey
        createdAt
      }
      webhooks {
        uuid
        name
        url
        secretKey
        createdAt
      }
    }
  }
`;
export type DeleteApiKeyMutationFn = Apollo.MutationFunction<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>;

/**
 * __useDeleteApiKeyMutation__
 *
 * To run a mutation, you first call `useDeleteApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApiKeyMutation, { data, loading, error }] = useDeleteApiKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteApiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>(DeleteApiKeyDocument, options);
}
export type DeleteApiKeyMutationHookResult = ReturnType<typeof useDeleteApiKeyMutation>;
export type DeleteApiKeyMutationResult = Apollo.MutationResult<DeleteApiKeyMutation>;
export type DeleteApiKeyMutationOptions = Apollo.BaseMutationOptions<
  DeleteApiKeyMutation,
  DeleteApiKeyMutationVariables
>;
export const RegisterMerchantAccountDocument = gql`
  mutation registerMerchantAccount($input: RegisterMerchantAccountInput!) {
    registerMerchantAccount(input: $input) {
      uuid
      name
      profileLanguage
      profileTimezone
    }
  }
`;
export type RegisterMerchantAccountMutationFn = Apollo.MutationFunction<
  RegisterMerchantAccountMutation,
  RegisterMerchantAccountMutationVariables
>;

/**
 * __useRegisterMerchantAccountMutation__
 *
 * To run a mutation, you first call `useRegisterMerchantAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMerchantAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMerchantAccountMutation, { data, loading, error }] = useRegisterMerchantAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMerchantAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<RegisterMerchantAccountMutation, RegisterMerchantAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterMerchantAccountMutation, RegisterMerchantAccountMutationVariables>(
    RegisterMerchantAccountDocument,
    options
  );
}
export type RegisterMerchantAccountMutationHookResult = ReturnType<typeof useRegisterMerchantAccountMutation>;
export type RegisterMerchantAccountMutationResult = Apollo.MutationResult<RegisterMerchantAccountMutation>;
export type RegisterMerchantAccountMutationOptions = Apollo.BaseMutationOptions<
  RegisterMerchantAccountMutation,
  RegisterMerchantAccountMutationVariables
>;
export const RemoveWebhookDocument = gql`
  mutation removeWebhook($uuid: String!) {
    removeWebhook(uuid: $uuid) {
      uuid
      name
      profileLanguage
      profileTimezone
      apiKeys {
        uuid
        name
        publicKey
        secretKey
        createdAt
      }
      webhooks {
        uuid
        name
        url
        secretKey
        createdAt
      }
    }
  }
`;
export type RemoveWebhookMutationFn = Apollo.MutationFunction<RemoveWebhookMutation, RemoveWebhookMutationVariables>;

/**
 * __useRemoveWebhookMutation__
 *
 * To run a mutation, you first call `useRemoveWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWebhookMutation, { data, loading, error }] = useRemoveWebhookMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRemoveWebhookMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveWebhookMutation, RemoveWebhookMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveWebhookMutation, RemoveWebhookMutationVariables>(RemoveWebhookDocument, options);
}
export type RemoveWebhookMutationHookResult = ReturnType<typeof useRemoveWebhookMutation>;
export type RemoveWebhookMutationResult = Apollo.MutationResult<RemoveWebhookMutation>;
export type RemoveWebhookMutationOptions = Apollo.BaseMutationOptions<
  RemoveWebhookMutation,
  RemoveWebhookMutationVariables
>;
export const UpdateWalletAddressDocument = gql`
  mutation updateWalletAddress($walletAddress: String!) {
    updateWalletAddress(walletAddress: $walletAddress) {
      uuid
      name
      profileLanguage
      profileTimezone
      walletAddress
      apiKeys {
        uuid
        name
        publicKey
        secretKey
        createdAt
      }
      webhooks {
        uuid
        name
        url
        secretKey
        createdAt
      }
    }
  }
`;
export type UpdateWalletAddressMutationFn = Apollo.MutationFunction<
  UpdateWalletAddressMutation,
  UpdateWalletAddressMutationVariables
>;

/**
 * __useUpdateWalletAddressMutation__
 *
 * To run a mutation, you first call `useUpdateWalletAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWalletAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWalletAddressMutation, { data, loading, error }] = useUpdateWalletAddressMutation({
 *   variables: {
 *      walletAddress: // value for 'walletAddress'
 *   },
 * });
 */
export function useUpdateWalletAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateWalletAddressMutation, UpdateWalletAddressMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateWalletAddressMutation, UpdateWalletAddressMutationVariables>(
    UpdateWalletAddressDocument,
    options
  );
}
export type UpdateWalletAddressMutationHookResult = ReturnType<typeof useUpdateWalletAddressMutation>;
export type UpdateWalletAddressMutationResult = Apollo.MutationResult<UpdateWalletAddressMutation>;
export type UpdateWalletAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateWalletAddressMutation,
  UpdateWalletAddressMutationVariables
>;
export const GetMerchantAccountDocument = gql`
  query getMerchantAccount {
    getMerchantAccount {
      uuid
      name
      profileLanguage
      profileTimezone
      walletAddress
      apiKeys {
        uuid
        name
        publicKey
        secretKey
        createdAt
      }
      webhooks {
        uuid
        name
        url
        secretKey
        createdAt
      }
    }
  }
`;

/**
 * __useGetMerchantAccountQuery__
 *
 * To run a query within a React component, call `useGetMerchantAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMerchantAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMerchantAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMerchantAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMerchantAccountQuery, GetMerchantAccountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMerchantAccountQuery, GetMerchantAccountQueryVariables>(
    GetMerchantAccountDocument,
    options
  );
}
export function useGetMerchantAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMerchantAccountQuery, GetMerchantAccountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMerchantAccountQuery, GetMerchantAccountQueryVariables>(
    GetMerchantAccountDocument,
    options
  );
}
export type GetMerchantAccountQueryHookResult = ReturnType<typeof useGetMerchantAccountQuery>;
export type GetMerchantAccountLazyQueryHookResult = ReturnType<typeof useGetMerchantAccountLazyQuery>;
export type GetMerchantAccountQueryResult = Apollo.QueryResult<
  GetMerchantAccountQuery,
  GetMerchantAccountQueryVariables
>;
export const ListPaymentsDocument = gql`
  query listPayments($page: Int, $limit: Int, $where: ListPaymentsFilter) {
    listPayments(page: $page, limit: $limit, where: $where) {
      items {
        uuid
        amount
        description
        merchantWalletAddress
        buyerWalletAddress
        transactionHash
        state
        token {
          uuid
          name
          symbol
          description
          tokenAddress
        }
        createdAt
        updatedAt
      }
      pagination {
        totalItems
        itemsPerPage
        totalPages
        currentPage
      }
    }
  }
`;

/**
 * __useListPaymentsQuery__
 *
 * To run a query within a React component, call `useListPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPaymentsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListPaymentsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListPaymentsQuery, ListPaymentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListPaymentsQuery, ListPaymentsQueryVariables>(ListPaymentsDocument, options);
}
export function useListPaymentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListPaymentsQuery, ListPaymentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListPaymentsQuery, ListPaymentsQueryVariables>(ListPaymentsDocument, options);
}
export type ListPaymentsQueryHookResult = ReturnType<typeof useListPaymentsQuery>;
export type ListPaymentsLazyQueryHookResult = ReturnType<typeof useListPaymentsLazyQuery>;
export type ListPaymentsQueryResult = Apollo.QueryResult<ListPaymentsQuery, ListPaymentsQueryVariables>;
