import { useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import DialogCloseButton from '~/components/dialog-close-button';
import { useCreateWebhookMutation, useGetMerchantAccountQuery } from '~/graphql/merchant/types';
import { StyledComponentProps } from '~/types/material-ui';

const useStyles = makeStyles()((theme) => ({
  dialogCustom: {
    paddingTop: '5px !important',
  },
}));

interface Props extends DialogProps {
  onClose: () => void;
  classes?: StyledComponentProps<typeof useStyles>['classes'] & DialogProps['classes'];
}
const CreateWebhookDialog: React.FC<Props> = (props) => {
  const { open, onClose, ...others } = props;
  const { t } = useTranslation();

  const [createWebHook] = useCreateWebhookMutation();
  const schema = useMemo(
    () =>
      yup.object({
        name: yup.string().required().max(255),
        url: yup
          .string()
          .required()
          .test('is-valid-url', 'This field must be a valid URL', (value) => {
            try {
              new URL(value);
              return true;
            } catch (err) {
              return false;
            }
          }),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );
  const { enqueueSnackbar } = useSnackbar();

  interface FormValues extends yup.InferType<typeof schema> {}
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormValues) => {
    try {
      await createWebHook({
        variables: {
          input: data,
        },
      });
      onClose();
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return (
    <Dialog open={true} onClose={onClose} {...others} maxWidth="sm" fullWidth className="">
      <DialogCloseButton onClick={onClose} data-testid="close-button">
        <CloseIcon />
      </DialogCloseButton>
      <DialogTitle>
        <Typography variant="h5" color="primary" component="p">
          {t('webhooks.create_webhook')}
        </Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                label={'Name'}
                variant="outlined"
                margin="normal"
                error={!!errors.name}
                helperText={errors.name?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="url"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                label={'URL'}
                variant="outlined"
                error={!!errors.url}
                helperText={errors.url?.message}
                {...field}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isSubmitting}
            endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
          >
            {t('create')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateWebhookDialog;
