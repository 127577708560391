type EnvType = {
  REACT_APP_FIREBASE_PUBLIC_API_KEY: string;
  REACT_APP_FIREBASE_AUTH_DOMAIN: string;
  REACT_APP_FIREBASE_DATABASE_URL: string;
  REACT_APP_FIREBASE_PROJECT_ID: string;
  REACT_APP_FIREBASE_STORAGE_BUCKET: string;
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: string;
  REACT_APP_FIREBASE_APP_ID: string;
  REACT_APP_API_MERCHANT_SERVER: string;
  REACT_APP_NETWORK_RPC_URL: string;
  REACT_APP_BLOCK_EXPLORER_URL: string;
  REACT_APP_API_SUBQUERY_SERVER: string;
};
export const env: EnvType = process.env.NODE_ENV === 'production' ? (window as any)['env'] : process.env;
