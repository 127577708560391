import { useCallback, useMemo, useState } from 'react';

import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs } from '@mui/material';
import Link from '@mui/material/Link';
import { DataGridPro, DataGridProProps, GridColDef, GridFilterModel, GridToolbar } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import UserLayout from '~/components/app-layout/user-layout';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE, ROWS_PER_PAGE_OPTIONS } from '~/constants/view';
import { AppRouteEnum } from '~/enums/route.enum';
import { useListPaymentsQuery } from '~/graphql/merchant/types';

const useStyles = makeStyles()((theme) => ({
  btnCreate: {
    marginLeft: 'auto',
  },
  secretKeyWrapper: {
    justifyContent: 'space-between !important',
  },
}));

const PaymentsPage = () => {
  const { classes } = useStyles(undefined, { props: {} });
  const { t } = useTranslation();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    page: DEFAULT_PAGE,
  });
  const [filterModel, setFilterModel] = useState<GridFilterModel>();
  const { data: listPayments, loading: loadingListPayments } = useListPaymentsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      page: paginationModel.page + 1,
      limit: paginationModel.pageSize,
      where:
        filterModel?.items.reduceRight((acc, item) => {
          return { ...acc, [item.field]: item.value };
        }, {}) || {},
    },
  });

  type Row = typeof rows[number];
  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'merchantWalletAddress',
        headerName: t('payment_page.address'),
        minWidth: 140,
        sortable: false,
        flex: 1,
      },
      {
        field: 'currency',
        valueGetter: (params) => params.row?.token?.name,
        headerName: t('payment_page.currency'),
        minWidth: 150,
        filterable: false,
        sortable: false,
      },
      {
        field: 'amount',
        headerName: t('payment_page.amount'),
        minWidth: 150,
        filterable: false,
        sortable: false,
      },
      {
        field: 'state',
        headerName: t('payment_page.state'),
        minWidth: 150,
        filterable: false,
        sortable: false,
      },
      {
        field: 'description',
        headerName: t('payment_page.description'),
        sortable: false,
        filterable: false,
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'createdAt',
        headerName: t('created_at'),
        minWidth: 200,
        sortable: false,
        filterable: false,
        flex: 1,
        valueFormatter: ({ value }) => (value ? moment(value) : '-'),
      },
    ],
    [t]
  );

  const rows = useMemo(
    () =>
      (listPayments?.listPayments?.items || []).map((item) => {
        return {
          id: item.uuid,
          ...item,
        };
      }),
    [listPayments]
  );

  const onPaginationModelChange = useCallback<NonNullable<DataGridProProps['onPaginationModelChange']>>((model) => {
    setPaginationModel(model);
  }, []);

  const onFilterModelChange = useCallback<NonNullable<DataGridProProps['onFilterModelChange']>>(
    (model) => {
      setFilterModel(model);
    },
    [setFilterModel]
  );

  return (
    <UserLayout>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <Link
          component={RouterLink}
          to={AppRouteEnum.PAYMENTS}
          color="text.primary"
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          {t('payment_page.title')}
        </Link>
      </Breadcrumbs>
      <div style={{ width: '100%', height: 500 }}>
        <DataGridPro
          pagination
          rows={rows}
          loading={loadingListPayments}
          columns={columns}
          paginationMode="server"
          filterMode="server"
          rowCount={listPayments?.listPayments?.pagination?.totalItems || 0}
          pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
          paginationModel={paginationModel}
          onPaginationModelChange={onPaginationModelChange}
          filterModel={filterModel}
          onFilterModelChange={onFilterModelChange}
          components={{
            Toolbar: GridToolbar,
          }}
          disableVirtualization
          disableRowSelectionOnClick
          disableColumnMenu
        />
      </div>
    </UserLayout>
  );
};

export default PaymentsPage;
