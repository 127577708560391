import { useCallback, useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import AuthLayout from '~/components/auth-layout';
import { AppRouteEnum } from '~/enums/route.enum';
import { GetMerchantAccountDocument, LanguageEnum, useRegisterMerchantAccountMutation } from '~/graphql/merchant/types';
import { useLogout } from '~/hooks/use-logout';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  wrapper: {
    width: '100%',
    maxWidth: 560,
  },
  paper: {
    border: `1px solid ${theme.palette.divider}`,
    height: '100%',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  content: {
    textAlign: 'center',
  },
  language: {
    width: 155,
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
  },
  emailConfirmationWaring: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  btnLogout: {
    margin: theme.spacing(0.5, 0, 2, 0),
    textTransform: 'uppercase',
  },
  logo: {
    width: 279,
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    marginBottom: theme.spacing(4),
  },
}));

const SignUpPage: React.FC = () => {
  const { classes } = useStyles(undefined, { props: {} });
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const logout = useLogout();
  const navigate = useNavigate();
  const [registerMerchantAccount] = useRegisterMerchantAccountMutation({
    refetchQueries: [GetMerchantAccountDocument],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    document.title = t('sign_up');
  }, [t]);

  const schema = useMemo(
    () =>
      yup.object({
        name: yup.string().max(128).required(),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  interface FormValues extends yup.InferType<typeof schema> {}
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    async (data: FormValues) => {
      try {
        await registerMerchantAccount({
          variables: {
            input: {
              name: data.name,
              profileLanguage: i18n.language === 'ja' ? LanguageEnum.Ja : LanguageEnum.En,
            },
          },
        });
        navigate(AppRouteEnum.DASHBOARD);
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
    [registerMerchantAccount, i18n.language, navigate, enqueueSnackbar]
  );

  return (
    <AuthLayout headerTitle={t('sign_up')}>
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            fullWidth
            label={t('merchant_name')}
            variant="outlined"
            margin="normal"
            sx={{ marginTop: 0 }}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
            {...field}
          />
        )}
      />
      <Box display="flex" gap={2} flexWrap="wrap" justifyContent="center">
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          sx={{ marginTop: 2 }}
          disabled={isSubmitting}
          endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
        >
          {t('sign_up')}
        </Button>
        <Button color="primary" variant="outlined" onClick={logout} sx={{ marginTop: 2 }}>
          {t('logout')}
        </Button>
      </Box>
    </AuthLayout>
  );
};

export default SignUpPage;
