import { useCallback, useEffect, useRef } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import hljs from 'highlight.js';
import { useSnackbar } from 'notistack';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  copyButton: {
    position: 'absolute',
    top: 8,
    right: 6,
    zIndex: '1',
  },
  preClass: {
    backgroundColor: 'rgb(15, 23, 31)',
    borderRadius: '8px',
    padding: '10px',
    color: 'white',
    maxHeight: '150px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));
interface IProps {
  codeString: string;
}
const CodeBlock: React.FC<IProps> = ({ codeString }) => {
  const codeRef = useRef(null);
  const { classes } = useStyles(undefined, { props: {} });
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    codeRef.current && hljs.highlightBlock(codeRef.current);
  }, [codeString]);

  const onCopy = useCallback(() => {
    enqueueSnackbar(t('success_copy_to_clipboard'), {
      variant: 'info',
    });
  }, [enqueueSnackbar, t]);

  return (
    <div style={{ position: 'relative' }}>
      <CopyToClipboard text={codeString} onCopy={onCopy}>
        <IconButton size="small" className={classes.copyButton}>
          <ContentCopyIcon color="info" fontSize="inherit" />
        </IconButton>
      </CopyToClipboard>
      <pre className={classes.preClass}>
        <code ref={codeRef}>{codeString}</code>
      </pre>
    </div>
  );
};

export default CodeBlock;
