import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogProps, DialogTitle, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import CodeBlock from '~/components/code-block';
import DialogCloseButton from '~/components/dialog-close-button';
import { StyledComponentProps } from '~/types/material-ui';

const useStyles = makeStyles()((theme) => ({
  dialogCustom: {
    paddingTop: '5px !important',
    overflow: 'hidden',
  },
  dialogWrapper: {
    maxHeight: '500px',
  },
}));

interface Props extends DialogProps {
  onClose: () => void;
  value: { uuid: string; secretKey: string };
  classes?: StyledComponentProps<typeof useStyles>['classes'] & DialogProps['classes'];
}

const PrivateKeyInfoDialog: React.FC<Props> = (props) => {
  const {
    open,
    value: { uuid, secretKey },
    onClose,
    ...others
  } = props;
  const { t } = useTranslation();
  const { classes } = useStyles(undefined, { props: {} });

  return (
    <Dialog open={true} onClose={onClose} {...others} maxWidth="md" fullWidth>
      <DialogCloseButton onClick={onClose} data-testid="close-button">
        <CloseIcon />
      </DialogCloseButton>
      <DialogTitle>
        <Typography variant="h5" color="primary" component="p">
          {t('api_keys_page.api_key_information')}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogCustom}>
        <Typography variant="subtitle2">{t('api_keys_page.api_uuid')}:</Typography>
        <CodeBlock codeString={uuid} />
        <Typography variant="subtitle2">{t('api_keys_page.secret_key')}:</Typography>
        <CodeBlock codeString={secretKey} />
      </DialogContent>
    </Dialog>
  );
};

export default PrivateKeyInfoDialog;
