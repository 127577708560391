import { useEffect } from 'react';

import { RouteObject } from 'react-router';
import { useRoutes, useLocation } from 'react-router-dom';

import { AppRouteEnum } from '~/enums/route.enum';
import { WithApolloClient } from '~/graphql/client';
import { WithAccount } from '~/hooks/with-account';
import { WithFirebaseUser } from '~/hooks/with-firebase-auth';
import AboutPage from '~/pages/about';
import AddressRegister from '~/pages/address_register';
import APIKeys from '~/pages/api_keys';
import DashboardPage from '~/pages/dashboard';
import ErrorPage from '~/pages/error';
import PaymentsPage from '~/pages/payments';
import SignInPage from '~/pages/sign-in';
import Webhooks from '~/pages/webhook';

export const routerConfig: RouteObject[] = [
  {
    path: '/about',
    element: <AboutPage />,
  },
  {
    path: AppRouteEnum.DASHBOARD,
    element: (
      <WithFirebaseUser>
        <WithApolloClient>
          <WithAccount>
            <DashboardPage />
          </WithAccount>
        </WithApolloClient>
      </WithFirebaseUser>
    ),
  },
  {
    path: AppRouteEnum.PAYMENTS,
    element: (
      <WithFirebaseUser>
        <WithApolloClient>
          <WithAccount>
            <PaymentsPage />
          </WithAccount>
        </WithApolloClient>
      </WithFirebaseUser>
    ),
  },
  {
    path: AppRouteEnum.SIGN_IN,
    element: (
      <WithFirebaseUser>
        <SignInPage />
      </WithFirebaseUser>
    ),
  },
  {
    path: AppRouteEnum.ADDRESS_REGISTER,
    element: (
      <WithFirebaseUser>
        <WithApolloClient>
          <WithAccount>
            <AddressRegister />
          </WithAccount>
        </WithApolloClient>
      </WithFirebaseUser>
    ),
  },
  {
    path: AppRouteEnum.API_KEYS,
    element: (
      <WithFirebaseUser>
        <WithApolloClient>
          <WithAccount>
            <APIKeys />
          </WithAccount>
        </WithApolloClient>
      </WithFirebaseUser>
    ),
  },
  {
    path: AppRouteEnum.WEBHOOKS,
    element: (
      <WithFirebaseUser>
        <WithApolloClient>
          <WithAccount>
            <Webhooks />
          </WithAccount>
        </WithApolloClient>
      </WithFirebaseUser>
    ),
  },
  {
    path: '*',
    element: <ErrorPage code={404} />,
  },
];

const AppRouter = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return useRoutes(routerConfig);
};

export default AppRouter;
