import { createRef, useEffect, useMemo } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import { ThemeProvider } from '@mui/material/styles';
import * as dataGridPro from '@mui/x-data-grid-pro';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { setUpDataGrid } from './config/dataGrid';
import { AppRouteEnum } from './enums/route.enum';
import { WithConfirmationDialog } from './hooks/use-confirmation-dialog';
import { initFirebaseAuth } from './hooks/with-firebase-auth';
import AppRouter from './router/routerConfig';
import { generateTheme } from './styles/theme';
import { setupYupLocale } from './utils/yup.util';

import { env } from '~/env';

initFirebaseAuth({
  firebaseConfig: {
    apiKey: env.REACT_APP_FIREBASE_PUBLIC_API_KEY,
    authDomain: env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: env.REACT_APP_FIREBASE_APP_ID,
  },
  needEmailVerified: true,
  whenAuthed: AppRouteEnum.DASHBOARD,
  whenUnauthed: AppRouteEnum.SIGN_IN,
});

const notistackRef = createRef<SnackbarProvider>();
const onClickDismiss = (key: SnackbarKey) => () => {
  notistackRef.current?.closeSnackbar(key);
};

const App: React.FC = (props) => {
  const { i18n, t } = useTranslation();

  useMemo(() => {
    setupYupLocale(t);
    setUpDataGrid(t);
  }, [t]);

  const theme = useMemo(() => {
    const theme = generateTheme(i18n.language === 'ja' ? dataGridPro.jaJP : dataGridPro.enUS);
    return theme;
  }, [i18n.language]);

  useEffect(() => {
    const currentLanguage = window.localStorage.getItem('language');
    if (currentLanguage !== i18n.language) {
      window.localStorage.setItem('language', i18n.language);
    }
  }, [i18n.language]);

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <SnackbarProvider
            ref={notistackRef}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            action={(key) => (
              <IconButton onClick={onClickDismiss(key)} size="small" style={{ color: 'white' }}>
                <CloseIcon />
              </IconButton>
            )}
          >
            <WithConfirmationDialog>
              <AppRouter />
            </WithConfirmationDialog>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
