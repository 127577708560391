import { useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import DialogCloseButton from '~/components/dialog-close-button';
import { useCreateApiKeyMutation } from '~/graphql/merchant/types';
import { StyledComponentProps } from '~/types/material-ui';

const useStyles = makeStyles()((theme) => ({
  dialogCustom: {
    paddingTop: '5px !important',
  },
}));

interface Props extends DialogProps {
  onClose: () => void;
  classes?: StyledComponentProps<typeof useStyles>['classes'] & DialogProps['classes'];
}
const CreateApiKeyDialog: React.FC<Props> = (props) => {
  const { open, onClose, ...others } = props;
  const { t } = useTranslation();
  const { classes } = useStyles(undefined, { props: {} });

  const [createApiKey] = useCreateApiKeyMutation();
  const schema = useMemo(
    () =>
      yup.object({
        name: yup.string().max(128).required(),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );
  const { enqueueSnackbar } = useSnackbar();

  interface FormValues extends yup.InferType<typeof schema> {}
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormValues) => {
    try {
      await createApiKey({
        variables: {
          input: data,
        },
      });
      onClose();
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return (
    <Dialog open={true} onClose={onClose} {...others} maxWidth="sm" fullWidth className="">
      <DialogCloseButton onClick={onClose} data-testid="close-button">
        <CloseIcon />
      </DialogCloseButton>
      <DialogTitle>
        <Typography variant="h5" color="primary" component="p">
          {t('api_keys_page.create_api_key')}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogCustom}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              label={t('name')}
              variant="outlined"
              margin="normal"
              sx={{ marginTop: 0 }}
              error={!!errors.name?.message}
              helperText={errors.name?.message}
              {...field}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          disabled={isSubmitting}
          endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
          onClick={handleSubmit(onSubmit)}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateApiKeyDialog;
